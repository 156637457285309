import React from 'react';
import Banner from '../../includes/products/Banner';
import Details from '../../includes/products/Details';
import Features from '../../includes/products/Features';
import Application from '../../includes/products/Application';
import GetMe from '../GetMe/GetMe';

export default function Propellers() {
    const data = {
        image: require("../../../assets/images/design-banner.png"),
        heading: "VEKTAS PROPELLERS",
        paragraph: "Efficient UAV propellers are essential for prolonged flight times, increased payload capacity, and optimal drone performance. Vektas propellers cater to diverse drone applications with superior efficiency."
    };

    const details = {

        tittle: "OVERVIEW",
        paragraph: "Our revolutionary UAV propellers, meticulously crafted with cutting-edge technology, redefine efficiency and performance in the drone industry. Featuring an innovative design, our propellers offer a remarkable 20% increase in efficiency compared to conventional models, resulting in extended flight times and improved payload capacity. Manufactured using high-quality materials and advanced manufacturing processes, our propellers ensure durability and reliability in diverse operating conditions. With a focus on precision engineering and rigorous testing, we guarantee optimal performance and safety for our customers. Whether it's navigating complex terrains or achieving high-speed flights, our propellers excel in delivering unmatched performance, making them the preferred choice for drone enthusiasts and industry professionals alike. Experience the future of drone propulsion with our state-of-the-art UAV propellers."
    }

    const features = [
        {
            image: require("../../../assets/images/icons/efficient.png"),
            title: "15% more efficient"
        },
        {
            image: require("../../../assets/images/icons/Aerodynamics.png"),
            title: "Advanced Aerodynamics"
        },
        {
            image: require("../../../assets/images/icons/Robust.png"),
            title: "Robust Lightweight"
        },
        {
            image: require("../../../assets/images/icons/Aerospace.png"),
            title: "Aerospace-Grade Material"
        },
        {
            image: require("../../../assets/images/icons/Manufacturing.png"),
            title: "Local Manufacturing"
        },
        {
            image: require("../../../assets/images/icons/environmental.png"),
            title: "Reduced environmental impact"
        },
    ]

    const app = [
        {
            image: require("../../../assets/images/agricultural.png"),
            tittle: "AGRICULTURE",
            paragraph: "Our UAV propellers aid in precision farming, enabling farmers to monitor crops, assess soil conditions, and optimize irrigation, ultimately enhancing crop yields and reducing resource consumption."
        },
        {
            image: require("../../../assets/images/Infrastructure.png"),
            tittle: "Infrastructure Inspection",
            paragraph: "Equipped with our propellers, drones facilitate efficient inspection of bridges, buildings, and other structures, providing detailed aerial imagery and data to detect defects and ensure structural integrity."
        },
        {
            image: require("../../../assets/images/Wildlife.jpg"),
            tittle: "Wildlife Conservation",
            paragraph: "Utilizing our propellers, drones support wildlife monitoring efforts by conducting aerial surveys, tracking animal movements, and identifying endangered species habitats, contributing to conservation initiatives and biodiversity preservation."
        }

    ]

    return (
        <>
            <Banner imageSrc={data.image} heading={data.heading} paragraph={data.paragraph} />
            <Details tittle={details.tittle} paragraph={details.paragraph} />
            <Features data={features} />
            <Application data={app} />
            <GetMe />
        </>
    );
}