import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import PrimaryButton from '../../includes/button/PrimaryButton';

import drone from '../../../assets/images/home-banner.gif'

export default function WhoWeAre() {
    const experianceSectionRef = useRef(null);

    useEffect(() => {
        // Function to handle the counting animation
        function runCountAnimation() {
            let valueDisplays = document.querySelectorAll(".num");
            let interval = 4000;

            valueDisplays.forEach((valueDisplay) => {
                let startValue = 0;
                let endValue = parseInt(valueDisplay.getAttribute("data-val"));
                let duration = Math.floor(interval / endValue);
                let counter = setInterval(function () {
                    startValue += 1;
                    valueDisplay.textContent = startValue;
                    if (startValue === endValue) {
                        clearInterval(counter);
                    }
                }, 90); // Increase the delay to slow down the counting
            });
        }

        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    runCountAnimation();
                    observer.disconnect();
                }
            });
        }, { threshold: 0.9 });

        if (experianceSectionRef.current) {
            observer.observe(experianceSectionRef.current);
        }

        // Cleanup observer on component unmount
        return () => {
            if (experianceSectionRef.current) {
                observer.unobserve(experianceSectionRef.current);
            }
        };
    }, []);

    return (
        <>
            <MainContainer>
                <Wrapper className='wrapper'>
                    <Left>
                        <Tittle>WHO WE ARE</Tittle>
                        <ImageContainer>
                            <Image src={drone} alt="image" />
                        </ImageContainer>
                    </Left>
                    <Right>
                        <Content>
                            Vektas Technologies Private Limited officially founded in July 2022, is a dynamic force in the technology landscape, specializing in the evolution of drone technologies and their versatile applications. Our focus extends beyond conventional boundaries, as we consistently pioneer groundbreaking solutions tailored to meet the unique demands of diverse industries. With an unwavering commitment to engineering brilliance and innovation, Vektas stands as a beacon of progress, offering end-to-end services that seamlessly integrate drone capabilities into various sectors.
                        </Content>
                        <Button>
                            <PrimaryButton text='KNOW MORE' to={'/explore'} />
                        </Button>
                    </Right>
                </Wrapper>

                <CountSection className='wrapper'>
                    <CountUl>
                        {/* <Count>
                            <SpanCount>2022</SpanCount>
                            <SpanText className="text">Since</SpanText>
                        </Count> */}
                        <Count>
                            <div style={{ display: "flex", alignItems: "center", justifyItems: "center" }}>
                                <SpanCount className="num" ref={experianceSectionRef} data-val="200">000</SpanCount>
                                <SpanPlus>+</SpanPlus>
                            </div>
                            <SpanText className="text">Hours Running</SpanText>
                        </Count>
                        <Count>
                            <div style={{ display: "flex", alignItems: "center", justifyItems: "center" }}>
                                <SpanCount className="num" ref={experianceSectionRef} data-val="20">00</SpanCount>
                                <SpanPlus>+</SpanPlus>
                            </div>
                            <SpanText className="text">Employees</SpanText>
                        </Count>
                        <Count>
                            <div style={{ display: "flex", alignItems: "center", justifyItems: "center" }}>
                                <SpanCount className="num" ref={experianceSectionRef} data-val="10">00</SpanCount>
                                <SpanPlus>+</SpanPlus>
                            </div>
                            <SpanText className="text">Running Projects</SpanText>
                        </Count>
                        <Count>
                            <div style={{ display: "flex", alignItems: "center", justifyItems: "center" }}>
                                <SpanCount className="num" ref={experianceSectionRef} data-val="3">0</SpanCount>
                                <SpanPlus>+</SpanPlus>
                            </div>
                            <SpanText className="text">Awards</SpanText>
                        </Count>
                    </CountUl>
                </CountSection>

                {/* <VideoPlayer style={{ borderRadius: '50px', overflow: 'hidden' }} className='wrapper'>
                    <ReactPlayer
                        url={demo}
                        width="100%"
                        height="100%"
                        controls={true}
                    />
                </VideoPlayer> */}
            </MainContainer>
        </>
    )
}
const MainContainer = styled.div`
    padding: 60px;
    animation: drop 1.5s ease;
    background: #fff;
    @media all and (max-width: 480px) {
    padding: 30px;
        
    }
`;
const Wrapper = styled.div`
   display: grid;
   grid-template-columns: 1.5fr 2fr;
   grid-gap: 22px;
   @media all and (max-width: 980px) {
        grid-template-columns: 2fr 1.5fr; 
    }
       @media all and (max-width: 980px) {
        grid-template-columns: 2fr ; 
    }

`;
const Left = styled.div`
`;
const Right = styled.div`
`;
const Tittle = styled.h4`
    font-weight: 400;
    color: #000;
    font-family: "kenexy";
    font-size: 56px;
    @media all and (max-width: 1280px) {
        font-size: 40px;
    }
    @media all and (max-width: 768px) {
        text-align: center;
    }
      @media all and (max-width: 480px) {
        font-size: 34px;
    }
`;
const ImageContainer = styled.div`
    margin-top: 40px;
`;
const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`;

const Content = styled.p`
    font-size: 20px;
    text-align: justify;
    font-family: 'montserrat';
    color: #000;
    @media all and (max-width: 1280px) {
        font-size: 18px;
    }
       @media all and (max-width: 980px) {
        font-size: 15px;
    }
  
`;
const Button = styled.div`
    margin-top: 40px;
    display: flex;
    align-items: end;
    justify-content: end;
`;
const CountSection = styled.div`
    margin-top: 60px;
`;
const CountUl = styled.ul`
   display: flex;
   align-items: center;
   justify-content: space-between;

   @media all and (max-width: 768px) {
        display: grid;
        grid-template-columns: 2fr  1fr;
        grid-gap: 30px;
    }
`;
const Count = styled.li`

border-right: 2px solid #000;
    padding-right: 60px;
        &:last-child {
        border-right: none;
    }

    @media all and (max-width: 1180px) {
        padding-right: 30px;
    }
    @media all and (max-width: 768px) {
        border-right: none;
    }
`;

const SpanCount = styled.span`
    font-size: 70px;
    color: #000;
    display: block;
    font-family: "kenexy";
     @media all and (max-width: 1280px) {
        font-size: 50px;
    }
      @media all and (max-width: 980px) {
        font-size: 38px;
    }
          @media all and (max-width: 768px) {
        font-size: 32px;
    }
`;

const SpanPlus = styled.span`
    font-size: 70px;
    color: #000;
    display: block;
    font-family: "kenexy";
     @media all and (max-width: 1280px) {
        font-size: 50px;
    }
       @media all and (max-width: 980px) {
        font-size: 38px;
    }
        @media all and (max-width: 768px) {
        font-size: 38px;
    }
`;
const SpanText = styled.span`
    font-size: 18px;
    color: #000;
    font-family: 'montserrat';
       
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
`;
const VideoPlayer = styled.div`
width: 80%;
margin-top: 50px;
margin: 50px  auto 0;
`;
