import React from 'react';
import Banner from '../../includes/products/Banner';
import Details from '../../includes/products/Details';
import Features from '../../includes/products/Features';
import Application from '../../includes/products/Application';
import GetMe from '../GetMe/GetMe';


export default function VtolDrone() {
    const data = {
        image: require("../../../assets/images/slide3.jpg"),
        heading: "VTOL Drones",
        paragraph: "In diverse applications, the VTOL drone by Vektas fulfills the need for extended flight time, offering efficient solutions in surveillance, agriculture, and logistics."
    };

    const details = {

        tittle: "OVERVIEW",
        paragraph: "Our revolutionary UAV propellers, meticulously crafted with cutting-edge technology, redefine efficiency and performance in the drone industry. Featuring an innovative design, our propellers offer a remarkable 20% increase in efficiency compared to conventional models, resulting in extended flight times and improved payload capacity. Manufactured using high-quality materials and advanced manufacturing processes, our propellers ensure durability and reliability in diverse operating conditions. With a focus on precision engineering and rigorous testing, we guarantee optimal performance and safety for our customers. Whether it's navigating complex terrains or achieving high-speed flights, our propellers excel in delivering unmatched performance, making them the preferred choice for drone enthusiasts and industry professionals alike. Experience the future of drone propulsion with our state-of-the-art UAV propellers."
    }

    const features = [
        {
            image: require("../../../assets/images/icons/Vtol-1.png"),
            title: "Weather-proof"
        },
        {
            image: require("../../../assets/images/icons/Vtol-2.png"),
            title: "Energy-Efficient Propulsion"
        },
        {
            image: require("../../../assets/images/icons/Vtol-3.png"),
            title: "Precision Navigation System"
        },
        {
            image: require("../../../assets/images/icons/Vtol-4.png"),
            title: "Rapid Vertical Take-off"
        },
        {
            image: require("../../../assets/images/icons/Vtol-5.png"),
            title: "Versatile Payload Capacity"
        },
        {
            image: require("../../../assets/images/icons/Vtol-6.png"),
            title: "Extended Flight Duration"
        },
    ]

    const app = [
        {
            image: require("../../../assets/images/Emergency.png"),
            tittle: "Emergency Response",
            paragraph: "Emergency Response: The VTOL drone aids in rapid disaster assessment, delivering supplies, and locating survivors, enhancing the efficiency and effectiveness of emergency response efforts."
        },
        {
            image: require("../../../assets/images/Infrastructure.png"),
            tittle: "Infrastructure Inspection",
            paragraph: "With its vertical takeoff capability and autonomous flight, the drone excels in inspecting critical infrastructure, providing detailed assessments and aiding in preventive maintenance strategies."
        },
        {
            image: require("../../../assets/images/Wildlife.jpg"),
            tittle: "Surveillance and Security",
            paragraph: "The VTOL drone enhances surveillance capabilities, securing borders, critical installations, and public spaces. Its versatile payload and autonomous features make it a robust solution for security applications."
        }
      
    ]

    return (
        <>
            <Banner imageSrc={data.image} heading={data.heading} paragraph={data.paragraph} />
            <Details tittle={details.tittle} paragraph={details.paragraph} />
            <Features data={features} />
            <Application data={app} />
            <GetMe />
        </>
    );
}