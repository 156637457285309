import React from 'react';
import { BrowserRouter, Routes, Route, NavLink, Outlet } from "react-router-dom";
import Home from './screens/Home';
import Header from './includes/header/Header';
import Footer from './includes/footer/Footer';
import ExploreUs from './screens/ExploreUs';
import Service from './screens/Service';
import Contact from './screens/Contact';
import Design from './screens/Design';
import Propellers from './screens/products/Propellers';
import VtolDrone from './screens/products/VtolDrone';
import WindTunnel from './screens/products/WindTunnel';
import Disaster from './screens/products/Disaster';
import Swarm from './screens/products/Swarm';

export default function LandingPage() {
    return (
        <>
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/explore" element={<ExploreUs />} />
                    <Route path="/product/propellers" element={<Propellers />} />
                    <Route path="/product/disaster-management" element={<Disaster />} />
                    <Route path="/product/vtol-drone" element={<VtolDrone />} />
                    <Route path="/product/wind-tunnel" element={<WindTunnel />} />
                    <Route path="/product/swarm" element={<Swarm />} />
                    <Route path="/service" element={<Service />} />
                    <Route path="/design" element={<Design />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    );
}
