import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import Swal from "sweetalert2";

import Flogo from "../../../assets/images/icons/footer-logo.png";

import facebook from "../../../assets/images/icons/fb.png";
import instagram from "../../../assets/images/icons/insta.png";
import linkdin from "../../../assets/images/icons/linkdin.png";
import tweet from "../../../assets/images/icons/tweet.png";
import youtube from "../../../assets/images/icons/youtube.png";
import right from "../../../assets/images/icons/right.png";

export default function Footer() {
  const [email, setEmail] = useState("");
  const handleSubmit = async (e) => {
    // Define handleSubmit function
    e.preventDefault();

    // Email validation
    const emailRegex = /^[A-Za-z\._\-0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/;
    if (!emailRegex.test(email)) {
      // If email is not valid, show an error message
      Swal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address.",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
      return;
    }

    try {
      const formData = $("#submit-form").serialize();

      console.log("Form Data:", formData);

      const response = await $.ajax({
        url: "https://script.google.com/macros/s/AKfycbzoG3iYGIxVB3eicBrBUJRtlHJOiMAYlbRz81gUba0gaiwnqaxPHOEhhho4nFqRNo3cjQ/exec",
        data: formData,
        method: "post",
      });

      console.log("Response:", response);

      Swal.fire({
        icon: "success",
        title: "Form submitted successfully",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error("Error:", error);
      console.error("Error Stack Trace:", error.stack);

      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        text: "Please try again later.",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  };

  const [isMenu, setMenu] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    setMenu(false);
  }, []);

  return (
    <>
      <MainContainer>
        <div className="wrapper">
          <TopSection>
            <LogoContainer to="/">
              <Logo src={Flogo} alt="logo" />
            </LogoContainer>
            <Mid>
              <Ul>
                <Li>
                  <Tittle>USEFUL LINKS</Tittle>
                  <PageLink to="/explore">
                    <GoTo src={right} alt="go-to" />
                    explore us
                  </PageLink>
                  <PageLink to="/service">
                    <GoTo src={right} alt="go-to" />
                    service
                  </PageLink>
                  <PageLink to="/contact">
                    <GoTo src={right} alt="go-to" />
                    contact
                  </PageLink>
                </Li>
                <Li>
                  <Tittle>ADDRESS</Tittle>
                  <SubTittle>HEADQUARTERS</SubTittle>
                  <Location>
                    SRA B12, Shanthinagar, Near Technopark Phase-1, Kazhakootam
                    P.O, Kerala-695582
                  </Location>
                  <SubTittle>CORPORATE OFFICE</SubTittle>
                  <Location>
                    Seminar Block, IIIT Delhi, Okhla Phase III, Okhla Industrial
                    Estate, New Delhi, Delhi 110020
                  </Location>
                </Li>
                <Li>
                  <Tittle>CONTACT US</Tittle>
                  <SubTittle>Phone</SubTittle>
                  <Phone>+91 79940 23597</Phone>
                  <Phone>+91 95608 38543</Phone>
                  <Phone>+91 11269 07328</Phone>
                  <SubTittle style={{ marginTop: "30px" }}>EMAIL</SubTittle>
                  <Phone>vektastechnologies@gmail.com</Phone>

                  <Phone style={{ marginTop: "5px" }}>
                    {" "}
                    Timing (10:00 AM to 6:00 PM)
                  </Phone>

                  <Label htmlFor="email">
                    Join our newsletter by subscribing
                  </Label>
                  <EmailSection
                    id="submit-form"
                    onSubmit={handleSubmit}
                    method="post"
                    name="form"
                  >
                    <Input
                      type="email"
                      placeholder="ENTER YOUR EMAIL"
                      name="email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                    <Button type="submit">SEND</Button>
                  </EmailSection>
                </Li>
              </Ul>
            </Mid>
          </TopSection>
          <Social>
            <SubTittle
              style={{ borderBottom: "1px solid grey", marginBottom: "12px" }}
            >
              Follow us on
            </SubTittle>
            <LinksContainer>
              <Links
                href="https://www.facebook.com/people/Vektas-Technologies/61557564470393/"
                target="blank"
              >
                <Icon src={facebook} alt="facebook" />
              </Links>
              <Links
                href="https://in.linkedin.com/company/vektas-technologies-pvt-ltdco"
                target="blank"
              >
                <Icon src={linkdin} alt="linkdin" />
              </Links>
              <Links href="https://twitter.com/VektasT" target="blank">
                <Icon src={tweet} alt="twitter" />
              </Links>
              <Links
                href="https://www.instagram.com/vektastechnologies/"
                target="blank"
              >
                <Icon src={instagram} alt="instagram" />
              </Links>
              <Links
                href="https://www.youtube.com/@VektasTechnologies"
                target="blank"
              >
                <Icon src={youtube} alt="youtube" />
              </Links>
            </LinksContainer>
          </Social>
          <Bottom>
            <Span>
              © All Rights Reserved | Designed And Developed By{" "}
              <Tigrid href="https://tigrid.in" target="_blank">
                TIGRID
              </Tigrid>
            </Span>
          </Bottom>
        </div>
      </MainContainer>
    </>
  );
}

const MainContainer = styled.div`
  background: #000;
  padding: 60px 0 20px;
  position: relative;
  z-index: 100;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  @media all and (max-width: 1080px) {
    align-items: center;
    flex-wrap: wrap;
  }

`;
const LogoContainer = styled(Link)`
    width: 560px;
    /* height: 240px; */


  @media all and (max-width: 1080px) {
    width: 280px;
  
  }
  @media all and (max-width: 980px) {
   width: 240px;
  }
  @media all and (max-width: 480px) {
    width: 185px;
  }
`;
const Logo = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
`;
const P = styled.p`
  font-size: 18px;
  color: #fff;
  font-family: "montserrat";

  margin-top: 20px;
  @media all and (max-width: 640px) {
    font-size: 16px;
  }
  @media all and (max-width: 480px) {
    font-size: 15px;
  }
`;

const EmailSection = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  color: #fff;
  font-family: "montserrat";
  margin-top: 20px;
`;

const Input = styled.input`
  border: 2px solid #afafaf;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #fff;
  padding: 8px 18px;
  font-size: 14px;
  font-family: "kenexy";

  @media all and (max-width: 420px) {
    font-size: 12px;
    padding: 8px 10px;
  }
`;

const Button = styled.button`
  margin: 0 10px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: inline-block;
  font-size: 14px;
  font-family: "kenexy";
  font-family: "montserrat";
  padding: 8px 18px;
  cursor: pointer;
  border: 1px solid #fff;
  &:hover {
    background-color: #fff;
    color: #000;
  }
  color: #fff;
  @media all and (max-width: 1080px) {
    margin: 0 5px;
  }
  @media all and (max-width: 480px) {
    margin-left: 6px;
    font-size: 15px;
    padding: 12px 15px;
  }
  @media all and (max-width: 420px) {
    margin-left: 2px;
    font-size: 12px;
    padding: 6px 7px;
  }
`;

const Mid = styled.div`
  @media all and (max-width: 980px) {
    margin-top: 20px;
}
  @media all and (max-width: 768px) {
    //margin-top: 60px;
  }
`;
const Ul = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.5fr;
  grid-gap: 40px;
  @media all and (max-width: 980px) {
    grid-template-columns: 1fr 1fr;
  }
  @media all and (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;
const Li = styled.li`
  list-style: none;
  @media all and (max-width: 980px) {
    &:last-child {
      margin-top: 30px;
    }
  }

  @media all and (max-width: 480px) {
    margin-top: 15px;
  }
`;
const Tittle = styled.h4`
  font-size: 25px;
  color: #fff;
  font-family: "kenexy";
  font-family: "montserrat";

  border-bottom: 3px solid grey;
  @media all and (max-width: 1280px) {
    font-size: 18px;
  }
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`;
const SubTittle = styled.p`
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin-top: 18px;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-family: "montserrat";
  font-family: "montserrat";

  @media all and (max-width: 1280px) {
    font-size: 16px;
  }
`;

const Location = styled(Link)`
  font-size: 18px;
  color: #fff;
  display: block;
  font-family: "montserrat";
  font-family: "montserrat";

  line-height: 1.5;
  width: 89%;
  cursor: pointer;
  @media all and (max-width: 1080px) {
    font-size: 16px;
  }
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`;

const Phone = styled.a`
  font-size: 18px;
  display: block;
  color: #fff;

  font-family: "montserrat";

  margin-top: 6px;
  cursor: pointer;
  @media all and (max-width: 1080px) {
    font-size: 16px;
  }
`;
const GoTo = styled.img`
  height: 15px;
  width: 15px;
  margin-right: 10px;
`;
const PageLink = styled(Link)`
  font-size: 18px;
  padding-bottom: 5px;
  border-bottom: 1px solid grey;
  color: #fff;
  margin-top: 30px;
  display: block;
  cursor: pointer;
  font-family: "kenexy";
  font-family: "montserrat";

  margin-top: 20px;
  text-transform: uppercase;

  &:first-child {
    margin-top: 0;
  }

  @media all and (max-width: 1080px) {
    font-size: 16px;
  }
  @media all and (max-width: 980px) {
    font-size: 16px;
  }
`;
const Social = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LinksContainer = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-top: 50px;
  @media all and (max-width: 480px) {
    top: 20%;
  }
`;
const Links = styled.a`
  margin: 4px 10px;
  cursor: pointer;
  filter: invert(1);

  /* &:hover {
        filter: invert(1);
        -moz-opacity: 0.85;
        -khtml-opacity: 0.85;
        opacity: 0.85;
    } */
`;

const Icon = styled.img`
  width: 55px;
  height: 55px;
  display: block;
  object-fit: contain;
  @media all and (max-width: 480px) {
    width: 40px;
    height: 40px;
  }
`;

const Bottom = styled.div`
  border-top: 2px solid #fff;
  margin-top: 50px;
  text-align: center;
  padding-top: 40px;
`;
const Span = styled.span`
  font-size: 18px;
  color: #fff;
  font-family: "kenexy";
  font-family: "montserrat";

  @media all and (max-width: 1280px) {
    font-size: 15px;
  }
`;
const Tigrid = styled.a`
  color: #f46600;
  text-decoration: underline;
  font-family: "kenexy";
  font-family: "montserrat";
`;