import { useState } from 'react'
import styled from 'styled-components'

import "../../../assets/css/style.css"

import close from "../../../assets/images/icons/close.png"
import left from "../../../assets/images/icons/left.png"
import right from "../../../assets/images/icons/right.png"



const WSPGallery = ({ galleryImages }) => {

    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    // Previous Image
    const prevSlide = () => {
        slideNumber === 0
            ? setSlideNumber(galleryImages.length - 1)
            : setSlideNumber(slideNumber - 1)
    }

    // Next Image  
    const nextSlide = () => {
        slideNumber + 1 === galleryImages.length
            ? setSlideNumber(0)
            : setSlideNumber(slideNumber + 1)
    }

    return (
        <>
            <div className='wrapper'>

                {openModal &&
                    <div className='sliderWrap'>
                        <Icon src={close} className='btnClose' onClick={handleCloseModal} />
                        <Icon src={left} className='btnPrev' onClick={prevSlide} />
                        <Icon src={right} className='btnNext' onClick={nextSlide} />

                        <div className='fullScreenImage'>
                            <img src={galleryImages[slideNumber].img} alt='Image' />
                        </div>
                    </div>
                }

                {/* <br />
      Current slide number:  {slideNumber}
      <br />
      Total Slides: {galleryImages.length}
      <br /><br /> */}

                <div className='galleryWrap'>
                    {
                        galleryImages && galleryImages.map((slide, index) => {
                            return (
                                <div
                                    className='single'
                                    key={index}
                                    onClick={() => handleOpenModal(index)}
                                >
                                    <img src={slide.img} alt='Image' />
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </>
    )
}

export default WSPGallery

const Icon = styled.img`
    width: 35px;
    height: 35px;
    object-fit: cover;
    display: block;
    cursor: pointer;
`;