import React from 'react'
import styled from 'styled-components';


import bg from '../../assets/images/service-bg.jpg';
import rescue from "../../assets/images/flood-rescue.png"
import fire from "../../assets/images/fire-rescue.png"
import survillance from "../../assets/images/surveillanc-drone.jpg"
import agriculture from "../../assets/images/agricultural.png"
import medical from "../../assets/images/medical-drone.png"
import Scroll from './Home/Scroll';

import slide1 from '../../assets/images/slide1.jpg';
import slide2 from '../../assets/images/slide2.jpg';
import slide3 from '../../assets/images/slide3.jpg';
import GetMe from './GetMe/GetMe';

export default function Service() {


    return (
        <>
            <MainContainer>
                <BgContainer></BgContainer>
                <WhoWeAreContainer>
                    <div className='wrapper'>
                        <Ul>
                            <Li>
                                <ImgContainer><Img src={slide2} alt="Image" /></ImgContainer>
                                <Details>
                                    <Tittle className='gradient'>3D printing & cnc</Tittle>
                                    <Paragraph>Harnessing the power of 3D printing design and advanced numerical control (NC), we provide unparalleled solutions tailored to your unique needs. Whether you require intricate prototypes, custom components, or streamlined manufacturing processes, trust us to deliver excellence every step of the way. Explore the future of engineering with us today.</Paragraph>
                                    <Bottom>
                                        <Items>
                                            <Span></Span>  <Item>3D Printing Prototyping</Item>
                                        </Items>
                                        <Items>
                                            <Span></Span>  <Item>Precision NC Manufacturing</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Customized Engineering Solutions</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Advanced Technology Expertise</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Rapid Prototyping</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Precision Manufacturing</Item>
                                        </Items>
                                    </Bottom>
                                </Details>
                            </Li>
                            <Li>
                                <ImgContainer><Img src={rescue} alt="Image" /></ImgContainer>
                                <Details>
                                    <Tittle className='gradient'>FLOOD RESCUE DRONE</Tittle>
                                    <Paragraph>Vektas Flood Rescue Drones swiftly respond to water crises, equipped with advanced sensors and cameras for real-time monitoring, ensuring efficient and timely rescue operations to save lives and mitigate flood-related disasters.</Paragraph>
                                    <Bottom>
                                        <Items>
                                            <Span></Span>  <Item>Swift Water Rescues</Item>
                                        </Items>
                                        <Items>
                                            <Span></Span>  <Item>Advanced Sensors</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>High-Resolution Cameras</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Real-Time Monitoring</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Efficient Coordination</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Effective Operations</Item>
                                        </Items>
                                    </Bottom>
                                </Details>
                            </Li>
                            <Li>
                                <ImgContainer><Img src={fire} alt="Image" /></ImgContainer>
                                <Details>
                                    <Tittle className='gradient'>FIRE FIGHTING DRONE</Tittle>
                                    <Paragraph>Vektas Fire Fighting Drones are first responders, navigating flames with specialized equipment, enhancing firefighting capabilities, and safeguarding lives and property.</Paragraph>
                                    <Bottom>
                                        <Items>
                                            <Span></Span>  <Item>First responders in flames</Item>
                                        </Items>
                                        <Items>
                                            <Span></Span>  <Item>360° Visibility Illumination</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>AI-Enhanced Locating</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Long Flight Endurance</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Precision Delivery Capability</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Robust Propeller Efficiency</Item>
                                        </Items>
                                    </Bottom>
                                </Details>
                            </Li><Li>
                                <ImgContainer><Img src={survillance} alt="Image" /></ImgContainer>
                                <Details>
                                    <Tittle className='gradient'>SURVEILLANCE DRONE</Tittle>
                                    <Paragraph>Our Surveillance Drones set the standard for security, offering high-resolution imaging, real-time video streaming, and advanced analytics to empower organizations with unparalleled situational awareness.</Paragraph>
                                    <Bottom>
                                        <Items>
                                            <Span></Span>  <Item>High-Resolution Imaging</Item>
                                        </Items>
                                        <Items>
                                            <Span></Span>  <Item>Real-Time Video Streaming</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Advanced Analytics</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Tailored Security Solutions</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Public Safety Empowerment</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Critical Infrastructure Monitoring
                                            </Item>
                                        </Items>
                                    </Bottom>
                                </Details>
                            </Li><Li>
                                <ImgContainer><Img src={agriculture} alt="Image" /></ImgContainer>
                                <Details>
                                    <Tittle className='gradient'>AGRICULTURAL DRONE</Tittle>
                                    <Paragraph>Agricultural drones revolutionize farming through precise data collection, sustainable interventions, and enhanced efficiency, promoting environmental consciousness.</Paragraph>
                                    <Bottom>
                                        <Items>
                                            <Span></Span>  <Item>Precision Mapping</Item>
                                        </Items>
                                        <Items>
                                            <Span></Span>  <Item>Crop Monitoring</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Efficient Spraying</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Remote Sensing</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Customizable Flight Paths</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Integration with Farming Systems</Item>
                                        </Items>
                                    </Bottom>
                                </Details>
                            </Li>
                            <Li>
                                <ImgContainer><Img src={medical} alt="Image" /></ImgContainer>
                                <Details>
                                    <Tittle className='gradient'>Delivery DRONE</Tittle>
                                    <Paragraph>The Delivery Drone delivers life-saving supplies in flood-prone areas, utilizing advanced sensors and cameras for efficient assessment and aiding rescue teams with GPS navigation and obstacle avoidance technology.</Paragraph>
                                    <Bottom>
                                        <Items>
                                            <Span></Span>  <Item>Emergency Response Design</Item>
                                        </Items>
                                        <Items>
                                            <Span></Span>  <Item>GPS Navigation</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Efficient and Safe Navigation</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Efficient and Safe Navigation</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Precision Delivery Capability</Item>
                                        </Items>     <Items>
                                            <Span></Span>  <Item>Advanced Sensors & Cameras</Item>
                                        </Items>
                                    </Bottom>
                                </Details>
                            </Li>
                        </Ul>
                    </div>
                </WhoWeAreContainer>
            </MainContainer>
            <GetMe />
        </>
    )
}

const MainContainer = styled.div`
    /* padding-bottom: 60px; */
    animation: drop 1.5s ease;
`;

const BgContainer = styled.div`
    background-image: url(${bg});
    filter: brightness(70%);
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 750px;
    @media all and (max-width: 1280px) {
    height: 650px;

    }
    @media all and (max-width: 1180px) {
        height: 580px;
    }
    @media all and (max-width: 768px) {
        height: 480px;
    }
    @media all and (max-width: 530px) {
        height: 440px;
    }
    @media all and (max-width: 430px) {
        height: 360px;
    }
`;
const WhoWeAreContainer = styled.div`
    background: #fff;
    padding: 60px 0 0;
    position: relative;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    top: -100px;
    @media all and (max-width: 530px) {
    top: -46px;
        
    }
`;
const Ul = styled.ul`
    margin-top: 60px;
    &:last-child{
    margin-top:0;
    }
`;

const Li = styled.li`
    border: 2px solid rgba(47, 83, 164, 0.15);
    border-radius: 20px;
    display: flex;
    align-items: center;
    grid-gap: 20px;
    justify-content: flex-start;
    margin-bottom: 80px;
    padding: 20px;
    &:nth-child(even){
      flex-direction: row-reverse;
    }
    @media all and (max-width: 1080px) {
        flex-wrap: wrap;
        &:nth-child(even){
      flex-direction: row;
    }
    @media all and (max-width: 530px) {
    margin-bottom: 30px;
        
    }
    }
`;

const ImgContainer = styled.div`
    width: 38%;
    height: 380px;
    @media all and (max-width: 1180px) {
        height: 290px;
    }
    @media all and (max-width: 1080px) {
        width: 50%;
        margin: 0 auto;
    }
        @media all and (max-width: 768px) {
        width: 95%;
        margin: 0 auto;
    }
    @media all and (max-width: 480px) {
        height: 250px;
    }
      @media all and (max-width: 430px) {
        height: 200px;
    }
`;

const Details = styled.div`
    padding: 60px;
    width: 60%;

    @media all and (max-width: 1280px) {
        padding: 20px;

    }
    @media all and (max-width: 1080px) {
        width: 100%;

    }
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 16px;
`;
const Bottom = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    @media all and (max-width: 640px) {
    grid-template-columns: 1fr;
    grid-gap: 4px;
        
    }
`;

const Items = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 14px;
    margin-top: 30px;
`;

const Span = styled.span`
    background: linear-gradient(to right, rgba(47, 83, 164, 1), rgba(18, 19, 62, 1));
    width: 11px;
    height: 11px;
    display: block;

`;

const Item = styled.p`
    font-size: 16px;
    color: #000;
    font-family: 'montserrat';
`;

const Tittle = styled.h4`
    font-size: 26px;
    text-transform: uppercase;
    @media all and (max-width: 1280px) {
    font-size: 24px;
    }
`;


const Paragraph = styled.p`
    font-size: 18px;
    margin-top: 20px;
    font-family: "Montserrat";
    text-align: justify;

    @media all and (max-width: 1280px) {
    font-size: 16px;
    }
`;

