import React from 'react';
import styled from 'styled-components';
import "../../../assets/css/style.css"
import bannerVideo from '../../../assets/images/demo.mp4';

import background from '../../../assets/images/demo.gif';  //  video file
import facebook from '../../../assets/images/icons/fb.png';
import instagram from '../../../assets/images/icons/insta.png';
import linkdin from '../../../assets/images/icons/linkdin.png';
import tweet from '../../../assets/images/icons/tweet.png';
import youtube from '../../../assets/images/icons/youtube.png';



import PrimaryButton from '../../includes/button/PrimaryButton';

export default function Spotlight() {
    return (
        <MainContainer>
            <VideoBackground autoPlay loop muted playsInline >
                <source src={bannerVideo} />
            </VideoBackground>
            <MobileView>
            </MobileView>
            <Wrapper className='wrapper'>
                <TextContainer>
                    <Heading>DESIGNED TO ENABLE YOUR LIFE</Heading>
                    <SubTittle>Unleashing the Future with Innovation Drone Technology </SubTittle>
                </TextContainer>
                <ButtonContainer>
                    <PrimaryButton text='EXPLORE' to={'/service'} />
                </ButtonContainer>
            </Wrapper>
            <Social>
                <Link href='https://www.facebook.com/people/Vektas-Technologies/61557564470393/' target='blank'><Icon src={facebook} alt="facebook" /></Link>
                <Link href='https://in.linkedin.com/company/vektas-technologies-pvt-ltdco' target='blank'><Icon src={linkdin} alt="linkdin" /></Link>
                <Link href='https://twitter.com/VektasT' target='blank'><Icon src={tweet} alt="twitter" /></Link>
                <Link href='https://www.instagram.com/vektastechnologies/' target='blank'><Icon src={instagram} alt="instagram" /></Link>
                <Link href='https://www.youtube.com/@VektasTechnologies' target='blank'><Icon src={youtube} alt="youtube" /></Link>
            </Social>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    position: relative; 
    background: #FFF; 
    padding-top: 150px;
    height: 100vh;
    overflow: hidden; 
    animation: drop 1.5s ease;
`;

const VideoBackground = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    filter: brightness(70%);
`;


const MobileView = styled.div``;

const Wrapper = styled.div`
    position: relative; 
    z-index: 1; 
    text-align: center;
    bottom: 205px;
    @media all and (max-width: 980px) {
        bottom: 58px;
    }
    @media all and (max-width: 480px) {
        bottom: -136px;
    }
`;

const Social = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 40%;
    z-index: 1;
    @media all and (max-width: 480px) {
        top: 20%;
    }
`;

const Link = styled.a`
    margin: 4px 10px;
    cursor: pointer;
    &:hover {
        filter: invert(1);
        -moz-opacity: 0.85;
        -khtml-opacity: 0.85;
        opacity: 0.85;
    }
`;

const Icon = styled.img`
    width: 55px;
    height: 55px;
    display: block;
    object-fit: contain;
    @media all and (max-width: 480px) {
        width: 40px;
        height: 40px;
    }
`;

const TextContainer = styled.div`
    text-align: center;
    margin-top: 30%;
`;

const Heading = styled.h1`
    color: #fff;
    text-align: center;
    font-family: "kenexy";
    font-weight: 400;
    font-size: 60px;
    text-transform: uppercase;
    @media all and (max-width: 1280px) {
        font-size: 54px;
    }
    @media all and (max-width: 980px) {
        font-size: 40px;
    }
    @media all and (max-width: 480px) {
        font-size: 30px;
    }
`;

const SubTittle = styled.h3`
    margin-top: 20px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 24px;
    text-transform: capitalize;
      @media all and (max-width: 1280px) {
        font-size: 22px;
    }
    @media all and (max-width: 480px) {
        font-size: 20px;
    }
`;

const ButtonContainer = styled.div`
    margin-top: 40px;
`;
