import React, { useState } from 'react'
import styled from 'styled-components';
import $ from 'jquery';
import Swal from 'sweetalert2';
import '../../../assets/css/style.css';
import drone from "../../../assets/images/home-banner.gif"
import BasicMap from '../../includes/Map/BasicMap';


export default function GetMe() {

    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => { // Define handleSubmit function
        e.preventDefault();

        // Email validation
        const emailRegex = /^[A-Za-z\._\-0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/;
        if (!emailRegex.test(email)) {
            // If email is not valid, show an error message
            Swal.fire({
                icon: 'error',
                title: 'Invalid Email',
                text: 'Please enter a valid email address.',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
            });
            return;
        }

        try {
            const formData = $("#submit-form").serialize();

            console.log("Form Data:", formData);

            const response = await $.ajax({
                url: "https://script.google.com/macros/s/AKfycbzlb9fu9A1Tc9QIKob4xSfP7Vw8KgXpIFPxQnM2M3mNCJXfSU9xkFA93IzbmVKJRvA/exec",
                data: formData,
                method: "post",
            });

            console.log("Response:", response);

            Swal.fire({
                icon: 'success',
                title: 'Form submitted successfully',
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                window.location.reload();
            });
        } catch (error) {
            console.error("Error:", error);
            console.error("Error Stack Trace:", error.stack);

            Swal.fire({
                icon: 'error',
                title: 'Something went wrong',
                text: 'Please try again later.',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
            });
        }
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };


    return (
        <>
            <MainContainer id="contact">
                <Wrapper className='wrapper'>
                    <Container>
                        <Left>
                            <ImgContainer>
                                <Img src={drone} alt="Image" />
                            </ImgContainer>
                        </Left>
                        <Right>
                            <Tittle className='gradient'>
                                GET IN TOUCH
                            </Tittle>
                            <EmailSection id="submit-form" onSubmit={handleSubmit} method="post" name="form">
                                <Input type="text" placeholder='NAME*' name='name' required />
                                <Input type='tel' placeholder='PHONE NO*' name="phone" required />
                                <Input type='email' placeholder='EMAIL*' name='email' value={email} onChange={handleEmailChange} required />
                                <Input type='textarea' placeholder='MESSAGE' name='message' required />
                                <Button type='submit'>SEND</Button>
                            </EmailSection>
                        </Right>
                    </Container>

                </Wrapper>
            </MainContainer>
            <BottomContainer>
                <Wrapper className='wrapper'>
                    <ContactUs>
                        <Address>
                            <AddreassTittle>ADDRESS</AddreassTittle>
                            <Location>HEADQUARTERS</Location>
                            <LocationDetaile>
                                SRA B12, Shanthinagar, Near Technopark Phase I,
                                Kazhakootam P.O, Kerala-695582</LocationDetaile>
                            <Location>CORPORATE OFFICE</Location>
                            <LocationDetaile>
                                525, 5th Floor, Seminar Block, IIIT Delhi, Okhla Phase III,
                                Okhla Industrial Estate, New Delhi, Delhi 110020</LocationDetaile>
                        </Address>

                        <Contact>
                            <ContactTittle>PHONE NO</ContactTittle>
                            <ContactDetaile>+91 79940 23597</ContactDetaile>
                            <ContactDetaile>+91 95608 38543</ContactDetaile>
                            <ContactDetaile>+91 11269 07328</ContactDetaile>

                            <ContactTittle>EMAIL</ContactTittle>
                            <ContactDetaile>vektastechnologies@gmail.com</ContactDetaile>
                        </Contact>
                    </ContactUs>
                    <Map >
                        <BasicMap />
                    </Map>
                </Wrapper>
            </BottomContainer>
        </>
    )
}
const MainContainer = styled.div`
    background: #fff;
    padding: 150px 0 50px 0;
    animation: drop 1.5s ease;

`;
const Wrapper = styled.div``;
const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 22px;

    @media all and (max-width: 640px) {
    grid-template-columns: 1fr;
    }
`;
const Left = styled.div``;
const Tittle = styled.h4`
    font-size: 54px;
    @media all and (max-width: 1280px) {
    font-size: 34px;
        
    }
`;

const EmailSection = styled.form`
    display: grid;
`;

const Input = styled.input`
    margin-top: 20px;
    border: 2px solid #AFAFAF;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 10px 20px;
    font-size: 16px;
    font-family: 'kenexy';
`;

const Button = styled.button`
    border: 1px solid #000;
    margin-top: 20px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: inline-block;
    font-size: 18px;
    font-family: 'kenexy';
    /* margin-left: 20px; */
    padding: 13px 15px;
    cursor: pointer;
    color: #000;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start ;
    width: max-content;
    &:hover{
        background-color: #000;
        color: #fff;
    }
`;

const Right = styled.div``;
const ImgContainer = styled.div``;
const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;

`;


const BottomContainer = styled.div`
       background: linear-gradient(
        180deg,
        rgba(47, 83, 164, 1),
        rgba(255, 255, 255, 1)
    );
    padding: 50px 0;
`;
const Map = styled.div`
    width: 100%;
    /* height: 500px; */
    margin-top: 60px;
`;
const ContactUs = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 22px;
    justify-content: space-between;

    @media all and (max-width: 640px) {
     grid-template-columns: 1fr;
    }
`;
const Address = styled.div`
    /* width: 48%; */
`;
const AddreassTittle = styled.h4`
    font-size: 32px;
    font-family: 'kenexy';
    color: #fff;
    @media all and (max-width: 480px) {
    font-size: 24px;
        
    }
`;
const Location = styled.p`
    font-size: 22px;
    font-family: 'montserrat';
    color: #000;
    font-weight: 600;
    margin-top: 25px;
    @media all and (max-width: 480px) {
    font-size: 18px;
        
    }
`;
const LocationDetaile = styled.p`
    font-size: 18px;
    color: #000;
    font-family: 'montserrat';
      @media all and (max-width: 480px) {
    font-size: 16px;
        
    }
`;
const Contact = styled.div``;
const ContactTittle = styled.h4`
    font-size: 32px;
    font-family: 'kenexy';
    color: #fff;
    margin-top: 25px;
    &:first-child {
        margin-top: 0;
    }
      @media all and (max-width: 480px) {
        font-size: 24px;
    }
`;
const ContactDetaile = styled.p`
    font-size: 18px;
    margin-top: 15px;
    color: #000;
    font-family: 600;
    font-family: 'montserrat';
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;