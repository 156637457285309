import React from 'react'
import styled from 'styled-components'

export default function Application({ data }) {
    return (
        <>
            <MainContainer>
                <div className='wrapper'>
                    <Container>
                        <Heading className='gradient'>
                            APPLICATIONS
                        </Heading>
                        <Ul>
                            {data.map((item, index) => (
                                <Li>
                                    <TopContainer>
                                        <Img src={item.image} />
                                    </TopContainer>
                                    <Bottom>
                                        <Tittle className='gradient'>{item.tittle}</Tittle>
                                        <Content>{item.paragraph}</Content>
                                    </Bottom>
                                </Li>
                            ))}
                        </Ul>
                    </Container>
                </div>
            </MainContainer>
        </>
    )
}

const MainContainer = styled.div`
    padding: 80px 0;
      @media all and (max-width: 1180px) {
           padding: 60px 0;
    }
    @media all and (max-width: 480px) {
        padding: 40px 0;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    grid-gap: 20px;
`;

const Heading = styled.h1`
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    @media all and (max-width: 768px) {
    font-size: 34px;
    }
    @media all and (max-width: 480px) {
    font-size: 26px;
    }
`;

const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    grid-gap: 50px;
    margin-top: 60px;
    @media all and (max-width: 1180px) {
        grid-template-columns: 1fr 1fr ;
    }
    @media all and (max-width: 640px) {
        grid-template-columns: 1fr ;
    }
    @media all and (max-width: 480px) {
    margin-top: 16px;
    grid-gap: 30px;
    }
`;

const Li = styled.li`
    border: 1px solid rgba(47, 83, 164, 1);
    border-radius: 20px;
`;

const TopContainer = styled.div`
  @media all and (max-width: 480px) {
    height: 300px;
  }
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 20px;
`;

const Bottom = styled.div`
    padding: 20px;
    min-height: 300px;

    @media all and (max-width: 1180px) {
        min-height: 220px;
    }

`;

const Tittle = styled.h4`
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    @media all and (max-width: 768px) {
    font-size: 18px;
    }
   
`;
const Content = styled.p`
    font-size: 16px;
    color: #000;
    margin-top: 20px;
    font-family: 'montserrat';
    text-align: justify;
`;

