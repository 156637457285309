import React from 'react'
import styled from 'styled-components';

export default function Features({ data }) {



    return (
        <>
            <MainContainer>
                <div className='wrapper'>
                    <Container>
                        <Ul>
                            {data.map((item, index) => (
                                <Li key={index}>
                                    <TopContainer>
                                        <Img src={item.image} alt={item.title} />
                                    </TopContainer>
                                    <LiTittle>{item.title}</LiTittle>
                                </Li>
                            ))}
                        </Ul>
                    </Container>
                </div>
            </MainContainer>
        </>
    )
}
const MainContainer = styled.div`
   background-image: linear-gradient(to bottom, rgba(47, 83, 164, 1), rgba(18, 19, 62, 1));
    padding: 80px 0;
    @media all and (max-width: 480px) {
        padding: 55px 0;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    grid-gap: 20px;
`;

const Ul = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 80px;
    @media all and (max-width: 480px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }
`;

const Li = styled.li`

`;
const TopContainer = styled.div`
    width: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 1280px) {
        width: 70px;
    }
    @media all and (max-width: 1180px) {
        width: 60px;
    }
`;
const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 10px;
`;
const LiTittle = styled.h4`
    margin-top: 20px;
    font-size: 20px;
    color: #fff;
    font-family: 'montserrat';
    text-align: center;
    text-transform: capitalize;
    @media all and (max-width: 1280px) {
        font-size: 16px;
    }
    @media all and (max-width: 1180px) {
        font-size: 14px;
    }
`;
